//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import consumer_credit_createRefundPayment
    from '@/lib/data-service/haolv-default/consumer_credit_createRefundPayment'
// 结算还款详情
import credit_billDetail from "@/lib/data-service/haolv-default/consumer_credit_billDetail"
import Q from 'q'
import PaymentMethodSelector100
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.1.1/payment-method-selector-100/index.vue'

export default {
    components: {
        PaymentMethodSelector100,
    },
    data() {
        return {
            orderNo: '',
            billDetail: {},
            loading: false,
            paymentMethos: "1",
            paymentList: [
                {label: "在线支付", value: "1"},
                {label: "银行卡转账", value: "2"}
            ],
            isOnlinePay: false,
        };
    },
    computed: {
        billId() {
            return this.$route.query.billId;
        },
    },
    methods: {
        init() {
            credit_billDetail({
                billId: this.billId
            }).then((response) => {
                this.billDetail = response.datas;
            });
        },
        init_payment() {
            const self = this;
            // const {orderNo, businessContent, dealAmount} = this.payDetail;
            // 还款
            const init_100 = function () {
                self.$refs.aPaymentMethodSelector100.init({
                    // 在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            // companyId: ``,
                            // clubId: ``,//中间社id
                            orderNo: self.orderNo, //订单编号
                            businessContent: self.billDetail.businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里
                            // isUseCredit: true,//是否允许授信支付
                            subject: `还款`, //
                            // orderAmount: 3000,//订单金额，用于计算可以用哪些支付方式支付
                            // orderAmount: 0.05,//订单金额，用于计算可以用哪些支付方式支付
                            orderAmount: self.billDetail.noPayedAmount, //订单金额，用于计算可以用哪些支付方式支付
                        };
                        return Q.resolve(p);
                    },
                    // 支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        console.log('before_pay_event_handler');
                        return Q.resolve();
                    },
                    // 支付成功事件处理方法
                    pay_successful_event_handler() {
                        console.log('pay_successful_event_handler');
                        self.$message.success('还款成功');
                        setTimeout(() => {
                            self.$router.push({
                                name: 'admin-finance-credit'
                            });
                        }, 1000);
                    },
                    // 支付失败事件处理方法
                    pay_failed_event_handler() {
                        console.log('pay_failed_event_handler');
                        self.$message.error('支付失败，请重新发起支付');
                    },
                });
            };
            init_100();
        },
        to_payment() {
            const paymentMethos = this.paymentMethos;
            if (paymentMethos === '1') {
                this.createRefundPayment(this.billId);
            } else if (paymentMethos === '2') {
                this.isOnlinePay = false;
                this.$router.push({
                    name: 'admin-settlement-affirm',
                    query: {
                        billId: this.billId
                    }
                });
            }
        },
        createRefundPayment(id) {
            this.isOnlinePay = true;
            consumer_credit_createRefundPayment({
                billId: id
            }).then((res) => {
                this.orderNo = res.datas;
                this.init_payment();
            });
        },
    },
    created() {

    },
    mounted () {
        this.init();
    }
};
