// 结算还款账单详情：http://192.168.0.26:8765/doc.html#/haolv-consumer/t-us-company-credit-controller/billDetailUsingPOST_1
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_billDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/billDetail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_billDetail;